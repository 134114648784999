import React from "react"
import SeoHead from "../../components/global/seoHead"

import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import DharaMain from "../../components/branding/dhara-ayur-essentials/dharaMain"
import DharaFullwidth from "../../components/branding/dhara-ayur-essentials/dharaFullWidth"
import DharaMoreBranding from "../../components/branding/dhara-ayur-essentials/dharaMoreBranding"


import brandingFeaturedImage from "../../../static/branding/dhara-ayur-essentials/dhara-ayur-essentials-featured-image.png"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/branding/dhara.scss"


export const Head = () => (
    <>
        <SeoHead 
            ogImage={brandingFeaturedImage}
            title="Ayurvedic Branding: Dhara Ayur Essentials | WowMakers"
            description="Experience the blend of tradition and modernity in Dhara Ayur Essentials' brand. Holistic design for a holistic brand."
        />
    </>
)

const DharaAyurEssentialsBranding = () => {
    return (
        <Layout>
            <Seo
                bid="branding-dhara"
                bclass="case-studies-page branding"
                >
            </Seo>
            <ScrollIndicationHeightZero />
            <DharaMain />
            <DharaFullwidth />
            <DharaMoreBranding />
        </Layout>
    )
}

export default DharaAyurEssentialsBranding
